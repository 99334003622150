@media (min-width: 290px) and (max-width: 768px) {
    body {
        overflow-x: hidden;
    }

    .container {
        max-width: rem(696px);
    }

    h1 {
        margin: rem(95px 0 50px 0);
        font-size: rem(62px);
    }
    
    h2 {
        margin: rem(70px 0);
        font-size: rem(43px);
        font-weight: 700;
        text-align: center;
    }
    
    h3 {
        margin: 0;
    }
    
    p {
        margin: 0;
    }

    .br-desk {
        display: none;
    }
    
    .br-mob {
        display: block;
    }
    
    .hero {
        background: url(../img/hero_bg-mob.jpg) 50% 50% no-repeat;
        background-size: cover;
    
        padding-top: rem(35px);
        
        overflow-x: hidden;
    
        .nav {
            .container {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
    
                .logo {
                    width: rem(377px);
                    height: rem(192px);
                }
    
                .menu {
                    position: absolute;
                    top: rem(-35px);
                    right: rem(-36px);

                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;
    
                    width: rem(384px);
                    height: rem(570px);

                    padding-left: rem(60px);
                    box-sizing: border-box;

                    transform: translateX(100%);

                    background-color: rgba(0, 21, 59, 0.95);
    
                    margin-top: 0;

                    z-index: 10;

                    transition: 0.5s;
    
                    a {
                        color: #fff;
                        font-size: rem(38px);
                        text-decoration: none;

                        &:not(:last-child) {
                            margin-bottom: rem(80px);
                        }
                    }
                }

                .menu.active {
                    transform: translateX(0%);
                }

                .menu-btn {
                    display: block;
                    width: rem(55px);
                    height: rem(43px);
                    position: relative;
                    z-index: 11;
                    margin-bottom: auto;
                  }
                  
                  .menu-btn:before,
                  .menu-btn:after {
                    background-color: #fff;
                    content: '';
                    display: block;
                    height: rem(4px);
                    transition: all 200ms ease-in-out;
                  }
                  .menu-btn:before {
                    box-shadow: rem(0 40px 0 #fff);
                    margin-bottom: rem(16px);
                  }

                  .menu-btn.active:before {
                    box-shadow: 0 0 0 rgba(255, 255, 255, 0);
                    transform: translateY(rem(10px)) rotate(45deg);
                  }
                  .menu-btn.active:after{
                    transform: translateY(rem(-10px)) rotate(-45deg);
                  }
            }
        }
    
        &>.container {
            p {
                font-size: rem(24px);
            }
        }
    }
    
    .block1 {
        padding-bottom: rem(50px);
    
        .container {
            p {
                font-size: rem(33px);
            }
    
            > p {
                text-align: justify;
            }
    
            .col-2 {
                margin: rem(65px 0);
    
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
    
                .texts {
                    width: 100%;
    
                    .text-semibold {
                        font-weight: 600;
                        text-align: center;
                    }
    
                    ul {
                        margin-top: rem(55px);
                        margin-bottom: rem(50px);
    
                        padding-left: rem(50px);
    
                        li {
                            font-size: rem(33px);
    
                            position: relative;
    
                            list-style: none;
    
                            margin-top: rem(15px);
    
                            &:before {
                                width: rem(20px);
                                height: rem(20px);
    
                                position: absolute;
                                top: rem(10px);
                                left: rem(-50px);
                                bottom: initial;
                                margin: auto;
                            }
                        }
                    }
                }
    
                .video {
                    width: rem(730px);
                    height: rem(415px);
    
                    iframe {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
    
    .block2 {
        background: url(../img/block2_bg-mob.jpg) 50% 50% no-repeat;
        background-size: cover;
    
        color: #fff;
    
        padding-top: rem(80px);
        padding-bottom: rem(390px);
    
        .container {
            h2 {
                text-align: center;
    
                margin-top: rem(0);
                margin-bottom: rem(50px);
    
                font-size: rem(62px);
            }
    
            p {
                font-size: rem(33px);
    
                span {
                    font-size: rem(33px);
                }
            }
        }
    }
    
    .block3 {
        padding-bottom: rem(550px);

        .container {
            p {
                font-size: rem(33px);
            }
    
            > p {
                text-align: justify;
            }
    
            h3 {
                text-align: center;
                font-size: rem(38px);
                font-weight: 600;
    
                margin: rem(50px 0);
    
                span {
                    text-transform: uppercase;
                    color: #004e9e;
                }
            }
    
            .scheme {
                position: relative;
    
                img {
                    width: rem(768px);
                    height: rem(891px);
    
                    display: block;
    
                    margin: 0 auto;
                    margin-top: rem(470px);
                    margin-left: rem(-36px);
                }
    
                .line {
                    display: none;
                }

                .line-mob {
                    display: block;
                    position: absolute;

                    p {
                        font-size: rem(33px);
                        text-transform: uppercase;
    
                        position: absolute;
    
                        &.small {
                            font-size: rem(26px);
                            text-transform: initial;
                        }
                    }

                    &.line-mob-1 {
                        background: url(../img/line1-mob.svg) 50% 50% no-repeat;
                        background-size: contain;

                        width: rem(354px);
                        height: rem(411px);

                        top: rem(-280px);
                        left: rem(180px);

                        .p1 {
                            width: rem(462px);

                            top: rem(-130px);
                            left: rem(-180px);

                            text-align: right;
                        }

                        .small {
                            width: rem(462px);

                            top: rem(20px);
                            left: rem(-180px);

                            text-align: right;
                        }
                        
                        .p2 {
                            width: rem(462px);

                            top: rem(160px);
                            left: rem(-180px);

                            text-align: right;
                        }
                    }

                    &.line-mob-2 {
                        background: url(../img/line2-mob.svg) 50% 50% no-repeat;
                        background-size: contain;

                        width: rem(354px);
                        height: rem(603px);

                        bottom: rem(-420px);
                        left: rem(110px);

                        .p1 {
                            width: rem(509px);

                            top: rem(120px);
                            left: rem(80px);
                        }
                        
                        .p2 {
                            width: rem(509px);

                            top: rem(413px);
                            left: rem(80px);
                        }
                    }
                }
            }
    
        }
    }
    
    .block4 {
        background: url(../img/block4-bg-mob.jpg) 50% 50% no-repeat;
        background-size: cover;
    
        padding-top: rem(80px);
        padding-bottom: rem(425px);
    
        .container {
            p {
                font-size: rem(38px);
                font-weight: 600;
                text-align: center;
            }
    
            ul {
                margin-top: rem(60px);
    
                padding-left: rem(50px);
    
                li {
                    font-size: rem(33px);
    
                    position: relative;
    
                    list-style: none;
    
                    margin-top: rem(15px);
    
                    &:before {
                        width: rem(20px);
                        height: rem(20px);
    
                        position: absolute;
                        top: rem(10px);
                        left: rem(-50px);
                        bottom: initial;
                        margin: auto;
                    }
                }
            }
        }
    }
    
    .block5 {
        background: url(../img/block5-bg-mob.jpg) 50% 50% no-repeat;
        background-size: cover;
    
        padding-top: rem(75px);
        padding-bottom: rem(70px);
    
        color: #fff;
    
        .container {
            h2 {
                margin: 0;
                margin-bottom: rem(70px);
            }
    
            .col-2 {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
    
                .col {
                    width: 100%;
    
                    p {
                        font-size: rem(38px);
                        font-weight: 600;
                        margin-bottom: rem(53px);
    
                        &:last-child {
                            margin-bottom: rem(53px);
                        }
                    }
    
                    &:last-child {
                        width: 100%;
    
                        p {
                            color: #fb2f3b;
                            margin-bottom: rem(53px);
    
                            &:last-child {
                                margin-bottom: rem(0);
                            }
                        }
                    }
                }
            }
        }
    }
    
    .block6 {
        padding-top: rem(80px);
        padding-bottom: rem(50px);
    
        .container {
            p {
                font-size: rem(33px);
    
                span {
                    font-weight: 700;
                }
    
                &.left {
                    font-size: rem(33px);
                    text-align: justify;
                    margin-top: rem(60px);
                    margin-bottom: rem(60px);
                }
    
                &.right {
                    width: 100%;
    
                    font-size: rem(33px);
                    text-align: justify;
                    margin-top: rem(60px);
                    margin-bottom: rem(60px);
                    margin-left: initial;
                }
            }
    
            a {
                color: #033367;
            }
    
            > p {
                text-align: justify;
            }
    
            .pics {
                width: 100%;
                height: auto;
    
                margin-top: rem(75px);
    
                position: relative;
    
                .pic1 {
                    background: url(../img/block6-pic1-mob.jpg) 50% 50% no-repeat;
                    background-size: contain;
    
                    width: rem(696px);
                    height: rem(845px);
    
                    position: relative;
                    left: initial;
                    bottom: initial;
                    margin: 0 auto;
    
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
    
                    p {
                        width: 83%;
                        margin-top: rem(50px);
                        font-size: rem(33px);
                        text-align: justify;
                    }
                }
    
                .pic2 {
                    display: none;
                }
            }
        }
    }
    
    .footer {
        background: url(../img/footer-bg-mob.jpg) 50% 50% no-repeat;
        background-size: cover;
    
        padding-top: rem(40px);
        padding-bottom: rem(145px);
    
        .container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
    
            .logo {
                width: rem(377px);
                height: rem(192px);
            }
    
            .contacts {
                display: flex;
                flex-direction: column;
    
                color: #fff;
    
                font-size: rem(38px);
    
                margin-top: rem(170px);
                margin-left: initial;
    
                a {
                    color: #fff;
                    text-decoration: none;
                    margin-top: rem(60px);
                }
            }
        }
    }
    
    .scrollTop {
        background: url(../img/scrollTop.svg) 50% 50% no-repeat;
        background-size: contain;
    
        width: rem(130px);
        height: rem(130px);
    
        position: fixed;
        bottom: rem(10px);
        left: 50%;
    
        transform: translateX(166%);
        
        &.stop {
            bottom: rem(813px);
        }
    }












































}