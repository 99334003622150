@import "../../bower_components/normalize.css/normalize.css";
@import "../../node_modules/sass-rem/rem";


body {
    scroll-behavior: smooth;
    
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: 400;

    background-color: #fff;

    box-sizing: border-box;

    position: relative;
}

@media (min-width: 769px) and (max-width: 1680px) {
    html {
        font-size: calc(6px + (16 - 6) * ((100vw - 769px) / (1680 - 769)));
    }
}

@media (min-width: 290px) and (max-width: 768px) {
    html {
        font-size: calc(6px + (16 - 6) * ((100vw - 300px) / (768 - 300)));
    }
}

.container {
    width: 100%;
    max-width: rem(1356px);

    position: relative;

    margin: 0 auto;
}

img {
    width: 100%;
    height: 100%;

    object-fit: contain;
}

h1 {
    font-weight: 400;
    margin: rem(90px 0 40px 0);
    font-size: rem(62px);
}

h2 {
    margin: rem(65px 0);
    font-size: rem(36px);
    font-weight: 700;
    text-align: center;
}

h3 {
    margin: 0;
}

p {
    margin: 0;
}

.br-desk {
    display: block;
}

.br-mob {
    display: none;
}

.hero {
    background: url(../img/hero_bg.jpg) 50% 50% no-repeat;
    background-size: cover;

    color: #fff;

    padding-top: rem(32px);

    height: 100vh;

    .nav {
        .container {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .logo {
                width: rem(251px);
                height: rem(129px);
            }

            .menu {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                width: rem(500px);

                margin-top: rem(-20px);

                a {
                    color: #fff;
                    font-size: rem(30px);
                    text-decoration: none;
                }
            }

            .menu-btn {
                display: none;
            }
        }
    }

    &>.container {
        p {
            font-size: rem(24px);
        }
    }
}

.block1 {
    padding-bottom: rem(50px);

    .container {
        p {
            font-size: rem(28px);
        }

        > p {
            text-align: center;
        }

        .col-2 {
            margin: rem(65px 0);

            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;

            .texts {
                width: rem(630px);

                .text-semibold {
                    font-weight: 600;
                }

                ul {
                    margin-top: rem(55px);

                    padding-left: rem(24px);

                    li {
                        font-size: rem(24px);

                        position: relative;

                        list-style: none;

                        margin-top: rem(15px);

                        &:before {
                            content: '';

                            background: url(../img/li.svg) 50% 50% no-repeat;
                            background-size: contain;

                            width: rem(11px);
                            height: rem(11px);

                            position: absolute;
                            top: 0;
                            left: rem(-24px);
                            bottom: 0;
                            margin: auto;
                        }
                    }
                }
            }

            .video {
                width: rem(730px);
                height: rem(415px);

                iframe {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

.block2 {
    background: url(../img/block2_bg.jpg) 50% 50% no-repeat;
    background-size: cover;

    color: #fff;

    padding-top: rem(80px);
    padding-bottom: rem(90px);

    .container {
        h2 {
            text-align: right;

            margin-bottom: rem(270px);

            font-size: rem(48px);
        }

        p {
            font-size: rem(28px);

            span {
                font-size: rem(24px);
            }
        }
    }
}

.block3 {
    .container {
        p {
            font-size: rem(28px);
        }

        > p {
            text-align: center;
        }

        h3 {
            text-align: center;
            font-size: rem(28px);
            font-weight: 700;

            margin: rem(50px 0);

            span {
                text-transform: uppercase;
                color: #004e9e;
            }
        }

        .scheme {
            position: relative;

            img {
                width: rem(1002px);
                height: rem(859px);

                display: block;

                margin: 0 auto;
                margin-top: rem(-70px);
            }

            .line {
                position: absolute;

                p {
                    font-size: rem(24px);
                    text-transform: uppercase;

                    position: absolute;

                    &.small {
                        font-size: rem(18px);
                        text-transform: initial;
                    }
                }

                &.line1 {
                    background: url(../img/line1.svg) 50% 50% no-repeat;
                    background-size: contain;
    
                    width: rem(316px);
                    height: rem(96px);
    
                    top: rem(210px);
                    left: rem(100px);

                    p {
                        top: rem(-90px);
                        left: rem(-20px);

                        text-align: right;

                        &.small {
                            width: rem(320px);

                            top: rem(16px);
                            left: rem(-91px);
                        }
                    }
                }
                &.line2 {
                    background: url(../img/line2.svg) 50% 50% no-repeat;
                    background-size: contain;
    
                    width: rem(342px);
                    height: rem(120px);
    
                    bottom: rem(230px);
                    left: rem(50px);

                    p {
                        top: rem(20px);
                        left: rem(-73px);

                        text-align: right;
                    }
                }
                &.line3 {
                    background: url(../img/line3.svg) 50% 50% no-repeat;
                    background-size: contain;
    
                    width: rem(368px);
                    height: rem(151px);
    
                    top: rem(269px);
                    right: rem(151px);
                    
                    p {
                        width: rem(364px);

                        top: rem(-177px);
                        left: rem(136px);

                        text-align: left;
                    }
                }
                &.line4 {
                    background: url(../img/line4.svg) 50% 50% no-repeat;
                    background-size: contain;
    
                    width: rem(369px);
                    height: rem(153px);
                    
                    bottom: rem(120px);
                    right: rem(150px);
                    
                    p {
                        width: rem(286px);

                        top: 0;
                        left: rem(138px);

                        text-align: left;
                    }
                }
            }

            .line-mob {
                display: none;
            }
        }

    }
}

.block4 {
    background: url(../img/block4-bg.jpg) 50% 50% no-repeat;
    background-size: cover;

    padding-top: rem(50px);
    padding-bottom: rem(50px);

    .container {
        p {
            font-size: rem(28px);
            font-weight: 600;
        }

        ul {
            margin-top: rem(55px);

            padding-left: rem(24px);

            li {
                font-size: rem(24px);

                position: relative;

                list-style: none;

                margin-top: rem(15px);

                &:before {
                    content: '';

                    background: url(../img/li.svg) 50% 50% no-repeat;
                    background-size: contain;

                    width: rem(11px);
                    height: rem(11px);

                    position: absolute;
                    top: 0;
                    left: rem(-24px);
                    bottom: 0;
                    margin: auto;
                }
            }
        }
    }
}

.block5 {
    background: url(../img/block5-bg.jpg) 50% 50% no-repeat;
    background-size: cover;

    padding-top: rem(50px);
    padding-bottom: rem(60px);

    color: #fff;

    .container {
        h2 {
            margin: 0;
            margin-bottom: rem(40px);
        }

        .col-2 {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .col {
                width: 50%;

                p {
                    font-size: rem(30px);
                    font-weight: 600;
                    margin-bottom: rem(46px);

                    &:last-child {
                        margin-bottom: rem(0);
                    }
                }

                &:last-child {
                    width: 32%;

                    p {
                        color: #fb2f3b;
                        margin-bottom: rem(32px);

                        &:last-child {
                            margin-bottom: rem(0);
                        }
                    }
                }
            }
        }
    }
}

.block6 {
    padding-top: rem(50px);
    padding-bottom: rem(50px);

    .container {
        p {
            font-size: rem(28px);

            span {
                font-weight: 700;
            }

            &.left {
                font-size: rem(24px);
                text-align: left;
                margin-top: rem(60px);
                margin-bottom: rem(60px);
            }

            &.right {
                width: 50%;

                font-size: rem(24px);
                text-align: left;
                margin-top: rem(60px);
                margin-bottom: rem(60px);
                margin-left: auto;
            }
        }

        a {
            color: #033367;
        }

        > p {
            text-align: center;
        }

        .pics {
            width: rem(1344px);
            height: rem(703px);

            margin-top: rem(55px);

            position: relative;

            .pic1 {
                background: url(../img/block6-pic1.jpg) 50% 50% no-repeat;
                background-size: contain;

                width: rem(640px);
                height: rem(604px);

                position: absolute;
                left: 0;
                bottom: 0;
                margin: auto;

                display: flex;
                align-items: flex-start;
                justify-content: center;

                p {
                    margin-top: rem(50px);
                    font-size: rem(24px);
                }
            }

            .pic2 {
                background: url(../img/block6-pic2.png) 50% 50% no-repeat;
                background-size: contain;

                width: rem(773px);
                height: rem(618px);

                position: absolute;
                top: 0;
                right: 0;
                margin: auto;
            }
        }
    }
}

.footer {
    background: url(../img/footer-bg.jpg) 50% 50% no-repeat;
    background-size: cover;

    padding-top: rem(94px);
    padding-bottom: rem(103px);

    .container {
        display: flex;
        flex-direction: row;
        align-items: center;

        .logo {
            width: rem(251px);
            height: rem(129px);
        }

        .contacts {
            display: flex;
            flex-direction: column;

            color: #fff;

            font-size: rem(30px);

            margin-left: rem(620px);

            a {
                color: #fff;
                text-decoration: none;
                margin-top: rem(10px);
            }
        }
    }
}

.scrollTop {
    background: url(../img/scrollTop.svg) 50% 50% no-repeat;
    background-size: contain;

    width: rem(88px);
    height: rem(88px);

    position: fixed;
    bottom: rem(10px);
    /* right: rem(283px); */
    left: 50%;

    transform: translateX(658%);

    opacity: 0;
    pointer-events: none;

    transition: opacity 0.3s;

    &.active {
        opacity: 1;
        pointer-events: all;
        cursor: pointer;
    }
    
    &.stop {
        position: absolute;
        bottom: rem(341px);
    }
}


@import "partials/app";